import { useEffect } from "preact/hooks";

function Slider() {
    useEffect(() => {
        // Initialize Owl Carousel after component mounts
        $(document).ready(function () {
            $(".owl-carousel").owlCarousel({
                loop: true,
                margin: 10,
                // nav: true,
                items: 1, // Show one item per slide
                autoplay: true,
                autoplayTimeout: 3000, // Time between slides (3 seconds)
                autoplaySpeed: 1000, // Transition speed (1 second)
                smartSpeed: 1500, // Smooth transition effect
                lazyLoad: true, // Load images only when they are in view
                autoplayHoverPause: true, // Pause on hover
                animateOut: "fadeOut", // Add fade out effect (optional)
            });
        });
    }, []);

    const isMobile = window.innerWidth <= 767;
    const isTablet = window.innerWidth >= 768 && window.innerWidth <= 1023;
    const isHdscreen = window.innerWidth >= 1023 && window.innerWidth <= 1439;
    const is4kscreen = window.innerWidth >= 1439 && window.innerWidth <= 2560;


    return (
        <div class="mx-auto lg:w-9/12 w-4/5 owl-carousel-hd">
            <div className="owl-carousel">
                <div className="item">
                    <div
                        className="slide"
                        style={{
                            background: "url(carousel1.webp)",
                            // height: isMobile
                            //     ? "140px"
                            //     : isTablet
                            //     ? "350px"
                            //     : isHdscreen
                            //     ? "450px"
                            //     : "500px",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                        }}
                    >
                    </div>
                </div>
                <div className="item">
                    <div
                        className="slide"
                        style={{
                            background: "url(carousel2.webp)",
                            // height: isMobile
                            //     ? "140px"
                            //     : isTablet
                            //     ? "350px"
                            //     : isHdscreen
                            //     ? "450px"
                            //     : "500px",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                        }}
                    >
                    </div>
                </div>
                {
                    /* <div className="item">
                    <div
                        className="slide"
                        style={{
                            background:
                                "url(carousel3.jpg)",
                            height: "500px",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                        }}
                    >
                    </div>
                </div> */
                }
            </div>
        </div>
    );
}

export default Slider;

